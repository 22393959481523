import $ from "jquery";
import axios from "axios";

import { Control } from "../../control";
import { loadScript } from "./utils";

export class SubscriptionIndexController extends Control {
  constructor(el, options) {
    super(el, options);
    this.stripe = null;

    loadScript("https://js.stripe.com/v3/", () => {
      axios.get("/subscription/key").then((response) => {
        this.stripe = window.Stripe(response.data.pubKey);

        $("#subscribe.is-disabled")
          .removeClass("is-disabled")
          .addClass("is-green");
      });
    });
  }
}

SubscriptionIndexController.prototype.events = {
  /** @this {SubscriptionIndexController} */
  ".js-radio click"($el) {
    $(".js-radio.checked")
      .removeClass("checked")
      .find("i")
      .removeClass("icon-moon-radio-on")
      .addClass("icon-moon-radio-off");

    $el
      .addClass("checked")
      .find("i")
      .addClass("icon-moon-radio-on")
      .removeClass("icon-moon-radio-off");
  },

  /** @this {SubscriptionIndexController} */
  "#subscribe:not(.is-disabled) click"($el) {
    $("#subscribe").addClass("is-disabled");
    const checked = $(".js-radio.checked").data();
    const coupon = $("#coupon").val();

    axios
      .post("/subscription/session", {
        cycle: checked.cycle,
        coupon_id: coupon,
      })
      .then((response) => {
        this.stripe
          .redirectToCheckout({
            sessionId: response.data.sessionId,
          })
          .then((result) => {
            // Browser of network error
            if (result.error) alert(result.error.message);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        $("#subscribe").removeClass("is-disabled");
        if (error.response) {
          alert(error.response.data.reason);
        } else {
          alert("Something went wrong, please try again.");
        }
      });
  },
};
