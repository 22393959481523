import $ from "jquery";
import axios from "axios";
import Turbolinks from "turbolinks";
import * as Sentry from "@sentry/browser";

import "./polyfills";
import { getUtmProps } from "./utm";
import { stash } from "./stash";
import {
  autoloadViews,
  highlightCodeBlocks,
  updateLayout,
  initTooltips,
  destroyTooltips,
} from "./ui";
import {
  AlertMessageBox,
  Articles,
  CodeChallengeSolutionGroups,
  CodeChallenges,
  CodeSnippets,
  CollectionModal,
  Collections,
  CommentsController,
  ConfirmModal,
  Dashboard,
  DropdownsController,
  FormsController,
  Header,
  Landing,
  NotificationsController,
  PersonalTrainer,
  Registrations,
  Subscription,
  StarsController,
  TabsController,
  ThemeController,
  Users,
  UsersComparisons,
} from "./controls";
import { debounce, param } from "./utils";
import { fadeOut } from "./animation";
import cable from "../channels/cable";
import PrivateChannel from "../channels/private";
import { initDatadog } from "./datadog";

import { initBraze } from "./braze";

/**
 * @typedef {object} CurrentUser
 * @property {string} id
 * @property {string} username
 * @property {string} email
 * @property {string} role
 * @property {string} jwt
 * @property {number} honor
 * @property {number} rank
 * @property {boolean} guest
 * @property {string} current_language
 * @property {string[]} starred_code_challenge_ids
 * @property {string[]} blocked_user_ids
 * @property {string[]} blocked_by_user_ids
 * @property {boolean} subscriber
 * @property {boolean} hideAds
 * @property {boolean} can_resolve_comments
 * @property {string} avatar_tag
 */

/**
 * @typedef {object} AppConfig
 * @property {Object.<string, object>} data
 * @property {Object.<string, string>} routes
 * @property {CurrentUser} currentUser
 * @property {string} env
 * @property {string} release
 * @property {string} pageControllerName
 * @property {boolean} darkMode
 */

/**
 * Encapsulates application state.
 */
export class App {
  /** @param config {AppConfig} */
  static setup(config) {
    if (this.instance !== null) {
      this.instance.update(config);
    } else {
      this.instance = new App(config);
    }
    return this.instance;
  }

  /** @param config {AppConfig} */
  constructor(config) {
    this.localStorage = stash("app");
    this.darkMode = false;
    this.assignConfig(config);
    this.initSentry();
    addGlobalEventListeners();
    if (this.currentUser.id && !this.currentUser.guest) {
      cable.subscribe(new PrivateChannel());
      if (this.currentUser.braze) {
        initBraze(this.currentUser.braze);
      }
    }
    this.configureControls();
    this.configureAxios();
    this.setPageController(config.pageControllerName);
    this.configureDatadog();
    this.configureSentry();
    // Get and store UTM props on load.
    this.utmProps = getUtmProps();
    this.afterLoad();
  }

  /** @param config {AppConfig} */
  update(config) {
    this.assignConfig(config);
    this.destroyControls();
    this.configureControls();
    this.setPageController(config.pageControllerName);
    this.configureSentry();
    this.afterLoad();
  }

  /** @param config {AppConfig} */
  assignConfig(config) {
    this.currentUser = config.currentUser;
    this.routes = config.routes;
    this.data = config.data;
    this.env = config.env;
    this.release = window.SENTRY_RELEASE;
  }

  destroyControls() {
    for (const c of globalControllers) {
      if (this[c] && !this[c].destroyed) {
        this[c].destroy();
        this[c] = null;
      }
    }
    destroyTooltips();
  }

  configureControls() {
    this.header = new Header("#main_header");
    this.confirmModal = new ConfirmModal("#confirm_modal");
    this.collectionModal = new CollectionModal("#collection_modal", {
      _app: this,
    });
    this.alerts = new AlertMessageBox("#global_alerts", {
      useSlide: true,
    });

    const $el = $("#app");
    this.tabs = new TabsController($el);
    this.dropdowns = new DropdownsController($el);
    this.comments = new CommentsController($el, { _app: this });
    this.forms = new FormsController($el);
    this.notifications = new NotificationsController($el, { _app: this });
    this.stars = new StarsController($el, { _app: this });
    this.theme = new ThemeController($el, { _app: this });

    highlightCodeBlocks();
    autoloadViews(this);
    updateLayout();
    setTimeout(() => {
      // This must be called after templates render.
      initTooltips();
    }, 1000);
  }

  /** @param {string} name */
  setPageController(name) {
    if (this.controller && !this.controller.destroyed) {
      this.controller.destroy();
      this.controller = null;
    }

    const Controller = getPageControllerConstructor(name);
    if (!Controller) return;
    this.controller = new Controller("#app", { _app: this });
  }

  configureAxios() {
    // Add 'x-requested-with' header so that the server can detect XHR.
    axios.defaults.headers.common["x-requested-with"] = "XMLHttpRequest";
    // Handle CSRF token automatically
    axios.defaults.xsrfCookieName = "CSRF-TOKEN";
    axios.defaults.xsrfHeaderName = "X-CSRF-Token";
    // Use cookie
    axios.defaults.withCredentials = true;

    axios.interceptors.request.use(
      (config) => {
        // Still necessary for authorizing while signing up.
        if (this.currentUser.jwt) {
          config.headers["authorization"] = this.currentUser.jwt;
        }
        sentryAjaxSend({
          type: config.method,
          url: config.url,
          data: config.data,
        });
        return config;
      },
      (error) => {
        // Request Error
        return Promise.reject(error);
      }
    );
  }

  initSentry() {
    Sentry.init({
      dsn: "https://dc778a04207f4100809dddc5f47d43fb@sentry.io/116947",
      environment: this.env,
      release: this.release,
      enabled: ["production", "staging"].includes(this.env),
      ignoreErrors: [
        /NS_ERROR_NOT_INITIALIZED/,
        "top.GLOBALS",
        "Can't execute code from a freed script",
        "Не удается выполнить программу из освобожденного сценария",
        "No se puede ejecutar código de un script liberado",
      ],
      blacklistUrls: [
        /graph\.facebook\.com/i,
        /connect\.facebook\.net\/en_US\/all\.js/i,
        /extensions\//i,
        /^chrome:\/\//i,
        /js\.intercomcdn\.com/i,
        /ravenjs/i,
      ],
    });
  }

  configureDatadog() {
    initDatadog({
      applicationId: "7b4b9ccf-4502-4910-b67d-926451a3559f",
      clientToken: "pub9148c9f0d8ac5486bb2204db9494acfc",
      service: "codewars-frontend",
      env: this.env,
      version: this.release,
      user: this.currentUser,
    });
  }

  configureSentry() {
    Sentry.configureScope((scope) => {
      if (this.currentUser) {
        scope.setUser({
          email: this.currentUser.email,
          username: this.currentUser.username,
          pro: this.currentUser.subscriber,
          current_language: this.currentUser.current_language,
        });
      }
      scope.setExtras({
        data: this.data,
        routes: this.routes,
      });
    });
    Sentry.addBreadcrumb({
      message: `${document.title}`,
      category: "Page Loaded",
      data: this.data,
    });
  }

  afterLoad() {
    if (this.controller && typeof this.controller.ready === "function") {
      this.controller.ready();
    }
    updateLayout();
    if ($(".ads-container").length) {
      if (this.currentUser.hideAds) {
        $(".ads-container").hide();
      }
    }

    if (Turbolinks.scrollTop > 0) {
      document.scrollingElement.scrollTo(0, Turbolinks.scrollTop);
      Turbolinks.scrollTop = 0;
    }
  }

  /**
   * @param {string} name
   * @param {Object.<string, object>} [params]
   * @param {Object.<string, object>} [query]
   * @returns {string}
   */
  route(name, params, query) {
    let path = this.routes[name] || name;
    // turn relative routes into full paths, as this helps with cookie/session issues.
    if (path.startsWith("/")) {
      path = `${window.location.protocol}//${window.location.host}${path}`;
    }

    if (query) {
      path += path.includes("?") ? "&" : "?";
      path += param(query);
    }

    if (!path) return "";
    if (params) {
      for (const key of Object.keys(params)) {
        path = path.replace(new RegExp(`%7B${key}%7D`, "g"), params[key]);
      }
    }
    return path;
  }
}

/** @type {App|null} */
App.instance = null;

const sentryAjaxSend = (options) => {
  Sentry.addBreadcrumb({
    message: "Ajax Send",
    category: "xhr",
    data: {
      type: options.type,
      url: options.url,
      data: options.data,
    },
  });
};

const globalControllers = Object.freeze([
  "confirmModal",
  "header",
  "collectionModal",
  "alerts",
  "tabs",
  "dropdowns",
  "comments",
  "forms",
  "notifications",
  "stars",
  "theme",
]);

const addGlobalEventListeners = () => {
  // Preserve scroll position for links with `[data-turbolinks-scroll="false"]`.
  // Can cause page thrashes. Use `<meta name="turbolinks-cache-control" content="no-preview">`?
  Turbolinks.scrollTop = 0;
  document.addEventListener(
    "turbolinks:click",
    (event) => {
      if (event.target.matches('[data-turbolinks-scroll="false"]')) {
        const scrollTop = document.scrollingElement.scrollTop;
        if (scrollTop > 0) {
          // Clear cache to prevent jumping when showing preview.
          Turbolinks.clearCache();
          Turbolinks.scrollTop = scrollTop;
        }
      }
    },
    false
  );

  const toggleScrolling = () => {
    $("html").toggleClass("scrolling", $(window).scrollTop() > 5);
  };
  $(window).on("scroll", debounce(toggleScrolling));
  toggleScrolling();

  $(document).on("click", ".alert-box a.close", (e) => {
    e.preventDefault();
    const $alertBox = $(e.target).closest(".alert-box");
    fadeOut($alertBox.get(0), {
      finish: () => {
        $alertBox.remove();
      },
    });
  });
};

const getPageControllerConstructor = (name) => {
  switch (name) {
    case "Articles.BlogController":
      return Articles.BlogController;
    case "Articles.DocsController":
      return Articles.DocsController;
    case "Articles.ForumController":
      return Articles.ForumController;
    case "CodeChallenges.CodeSnippetsController":
      return CodeChallenges.CodeSnippetsController;
    case "CodeChallenges.DiscussController":
      return CodeChallenges.DiscussController;
    case "CodeChallenges.EditorController":
      return CodeChallenges.EditorController;
    case "CodeChallenges.ListController":
      return CodeChallenges.ListController;
    case "CodeChallenges.PlayController":
      return CodeChallenges.PlayController;
    case "CodeChallenges.ShowController":
      return CodeChallenges.ShowController;
    case "CodeChallenges.SolutionsController":
      return CodeChallenges.SolutionsController;
    case "CodeChallenges.TranslationsController":
      return CodeChallenges.TranslationsController;
    case "CodeChallengeSolutionGroups.ShowController":
      return CodeChallengeSolutionGroups.ShowController;
    case "CodeSnippets.EditController":
      return CodeSnippets.EditController;
    case "CodeSnippets.IndexController":
      return CodeSnippets.IndexController;
    case "CodeSnippets.ShowController":
      return CodeSnippets.ShowController;
    case "CodeSnippets.TranslationsController":
      return CodeSnippets.TranslationsController;
    case "Collections.EditController":
      return Collections.EditController;
    case "Collections.IndexController":
      return Collections.IndexController;
    case "Collections.ShowController":
      return Collections.ShowController;
    case "Dashboard.IndexController":
      return Dashboard.IndexController;
    case "Landing.IndexController":
      return Landing.IndexController;
    case "Landing.StartController":
      return Landing.IndexController;
    case "PersonalTrainer.SetupController":
      return PersonalTrainer.SetupController;
    case "Subscription.IndexController":
      return Subscription.IndexController;
    case "Subscription.EditController":
      return Subscription.EditController;
    case "UsersComparisons.ShowController":
      return UsersComparisons.ShowController;
    case "Users.ShowController":
      return Users.ShowController;
    case "Users.RankingsController":
      return Users.RankingsController;
    case "Registrations.EditController":
      return Registrations.EditController;
    default:
      return null;
  }
};
